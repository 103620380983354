import React, {useState} from 'react';
import Mission from "../components/mission";
import Commitments from "../components/commitments";
import Developer from "../components/developer";
import Contact from "../components/contact";
import DesktopLanding from "../components/desktopLanding";
import MobileLanding from "../components/mobileLanding";

function Landing() {

    return (
        <>
            <div className={'block md:hidden'}>
                <MobileLanding />
            </div>
            <div className={'hidden md:block'}>
                <DesktopLanding />
            </div>
        </>
    );
}

export default Landing;