import React from 'react';

function Developer(props) {
    return (
        <>
            <div className={"bg-platinum-600 p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto shadow-xl"}>
                <div className={"bg-dutch_white-300 text-lg p-4 mx-auto w-fit rounded shadow"}>
                    <div
                        className="p-6 bg-gray-100 text-gray-800 rounded-lg shadow-md max-w-3xl mx-auto text-left mb-4">
                        <h2 className="text-xl font-bold mb-4 text-center">About Me</h2>

                        <div className={"text-left col-span-5 col-start-2 h-full flex flex-col mb-2"}>
                            My name is Justin Cochran, and I have been teaching at the secondary level in some capacity
                            since 2015. I initially set out to be an English teacher through a program in my undergrad
                            but
                            changed my instructional content to science as I entered into a teacher residency in grad school. In my first teaching
                            position, I was the sole science teacher at an alternative high school, which required me to
                            cover seven different preps each year that were all new to me starting out. As a result, I
                            have
                            always treated my classes as collaborative learning opportunities in which my students and I
                            engage with content and attempt to make sense of things together. In 2020, I transitioned to
                            math instruction and learned some rudimentary programming to provide opportunities for my
                            students to explore math applications in computer science. I found a passion for software
                            development and have since worked on a variety of projects aiming to improve my capacity as
                            an
                            instructor. I believe that my time spent tinkering with instructional strategies across
                            multiple
                            disciplines uniquely positions me to develop a platform that works for most instructors.
                        </div>
                        <div className={"text-left col-span-5 col-start-2 h-full flex flex-col mb-2"}>
                            My work on Brainmeter stems from a desire to minimize the amount of time and effort spent collecting
                            and
                            reviewing assessment data while maximizing the time that I spend interacting with my
                            students. As instructors, we need precise information about what each student knows,
                            understands, and is able to do so that we can best provide support for next steps. Without
                            Brainmeter, I often found myself choosing between spending hours each week collecting and
                            interpreting assessments or delivering uninformed instruction and accidentally ignoring and
                            expanding gaps in student learning. With this tool, which I originally developed just for my
                            own
                            classroom workflow, I can collect, analyze, and respond to data in minutes–often in the
                            middle
                            of a lesson.
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Developer;