import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import Landing from "./pages/landing";

function App() {
  return (
      <div className="App">
        <BrowserRouter>
            <Routes>
              <Route path='/' element={<Landing />}/>

            </Routes>

        </BrowserRouter>
      </div>
  );
}

export default App;
