import React, {useState} from 'react';
import Mission from "./mission";
import Commitments from "./commitments";
import Developer from "./developer";
import Contact from "./contact";

function DesktopLanding() {

    const [section, setSection] = useState("Mission");

    return (
        <>
            <div className={'flex justify-between bg-dutch_white-400 py-4 shadow-xl'}>
                <div className={"px-4 flex"}>
                    <img className={'h-[120px] w-[120px] my-auto'}
                         src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'}
                         alt={'Brainmeter'}/>
                    <p className={'text-6xl text-columbia_blue-800 my-auto ml-8'}>Brainmeter</p>
                </div>
            </div>
            <div className={'rounded mt-8 p-4 w-[80%] mx-auto'}>
                <div className={'flex gap-6 text-2xl'}>
                    {(section === "Mission") ?
                        <div className={'py-2 px-4 rounded-t bg-columbia_blue-500 select-none'} >
                            <p>Mission</p>
                        </div>
                        :
                        <div className={'py-2 px-4 rounded-t bg-columbia_blue-500 unselected-tab select-none cursor-pointer hover:bg-columbia_blue-400'} onClick={() => setSection('Mission')}>
                            <p>Mission</p>
                        </div>}
                    {(section === "Commitments") ?
                        <div className={'py-2 px-4 rounded-t bg-burnt_sienna-300 select-none'}>
                            <p>Commitments</p>
                        </div>
                        :
                        <div className={'py-2 px-4 rounded-t bg-burnt_sienna-300 select-none unselected-tab cursor-pointer hover:bg-burnt_sienna-200'} onClick={() => setSection('Commitments')}>
                            <p>Commitments</p>
                        </div>}
                    {(section === "Developer") ?
                        <div className={'py-2 px-4 rounded-t bg-platinum-600 select-none'}>
                            <p>Developer</p>
                        </div>
                        :
                        <div className={'py-2 px-4 rounded-t bg-platinum-600 select-none unselected-tab cursor-pointer hover:bg-platinum-500'} onClick={() => setSection('Developer')}>
                            <p>Developer</p>
                        </div>}
                    {(section === "Contact") ?
                        <div className={'py-2 px-4 rounded-t bg-paynes_gray-300 select-none'}>
                            <p>Contact</p>
                        </div>
                        :
                        <div className={'py-2 px-4 rounded-t bg-paynes_gray-300 select-none unselected-tab cursor-pointer hover:bg-paynes_gray-200'} onClick={() => setSection('Contact')}>
                            <p>Contact</p>
                        </div>}
                </div>
                {(section === "Mission") ?
                    <Mission />
                    : (section === "Commitments") ?
                        <Commitments />
                        : (section === "Developer") ?
                            <Developer />
                            : (section === "Contact") ?
                                <Contact />
                                : <></>}
            </div>
        </>
    );
}

export default DesktopLanding;