import React, {useState} from 'react';
import Mission from "./mission";
import Commitments from "./commitments";
import Developer from "./developer";
import Contact from "./contact";

function MobileLanding(props) {
    const [section, setSection] = useState('Mission')

    return (
        <>
            <div className={'bg-dutch_white-500 pt-4 pb-2'}>
                <div className={"px-4 flex"}>
                    <img className={'h-[60px] w-[60px] my-auto absolute'}
                         src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'}
                         alt={'Brainmeter'}/>
                    <p className={'text-4xl text-columbia_blue-800 my-auto mx-auto w-fit py-2'}>Brainmeter</p>
                </div>
            </div>
            <div className={'bg-columbia_blue-800 py-8'}>
                <p className={'text-dutch_white-300 text-xl'}>Brainmeter is a platform for supporting teacher planning, data collection, and instructional responsiveness to maximize the time we spend interacting with our students.</p>
            </div>
            <div className={'rounded p-1 mx-auto'}>
                <div className={'flex gap-1'}>
                    {(section === "Mission") ?
                        <div className={'py-2 px-2 rounded-t bg-columbia_blue-500 select-none'}>
                            <p>Mission</p>
                        </div>
                        :
                        <div
                            className={'py-2 px-2 rounded-t bg-columbia_blue-500 unselected-tab select-none cursor-pointer hover:bg-columbia_blue-400'}
                            onClick={() => setSection('Mission')}>
                            <p>Mission</p>
                        </div>}
                    {(section === "Commitments") ?
                        <div className={'py-2 px-2 rounded-t bg-burnt_sienna-300 select-none'}>
                            <p>Commitments</p>
                        </div>
                        :
                        <div
                            className={'py-2 px-2 rounded-t bg-burnt_sienna-300 select-none unselected-tab cursor-pointer hover:bg-burnt_sienna-200'}
                            onClick={() => setSection('Commitments')}>
                            <p>Commitments</p>
                        </div>}
                    {(section === "Developer") ?
                        <div className={'py-2 px-2 rounded-t bg-platinum-600 select-none'}>
                            <p>Developer</p>
                        </div>
                        :
                        <div
                            className={'py-2 px-2 rounded-t bg-platinum-600 select-none unselected-tab cursor-pointer hover:bg-platinum-500'}
                            onClick={() => setSection('Developer')}>
                            <p>Developer</p>
                        </div>}
                    {(section === "Contact") ?
                        <div className={'py-2 px-2 rounded-t bg-paynes_gray-300 select-none'}>
                            <p>Contact</p>
                        </div>
                        :
                        <div
                            className={'py-2 px-2 rounded-t bg-paynes_gray-300 select-none unselected-tab cursor-pointer hover:bg-paynes_gray-200'}
                            onClick={() => setSection('Contact')}>
                            <p>Contact</p>
                        </div>}
                </div>
                {(section === "Mission") ?
                    <Mission/>
                    : (section === "Commitments") ?
                        <Commitments/>
                        : (section === "Developer") ?
                            <Developer/>
                            : (section === "Contact") ?
                                <Contact/>
                                : <></>}
            </div>
        </>
    );
}

export default MobileLanding;