import React from 'react';

function Mission() {
    return (
        <>
            <div
                className={"bg-columbia_blue p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto shadow-xl"}>
                <div className={"bg-dutch_white-300 text-lg mx-auto w-fit p-4 rounded shadow"}>
                    <div
                        className="p-6 bg-gray-100 text-gray-800 rounded-lg shadow-md max-w-3xl mx-auto text-left mb-4">
                        <h2 className="text-xl font-bold mb-4 text-center">Mission Statement</h2>
                        <div className={"text-left col-span-5 col-start-2 h-full flex flex-col mb-6"}>
                            To empower educators and learners by providing innovative, accessible, and data-driven tools
                            that foster a deeper understanding, collaboration, and success in education. We are
                            dedicated to transforming educational experiences through technology, ensuring that every
                            learner has the opportunity to thrive in an inclusive and supportive environment.
                        </div>
                    </div>
                    <div
                        className="p-6 bg-gray-100 text-gray-800 rounded-lg shadow-md max-w-3xl mx-auto text-left mb-4">
                        <h2 className="text-xl font-bold mb-4 text-center">Vision Statement</h2>
                        <div className="text-left col-span-5 col-start-2 h-full flex flex-col mb-6">
                            <div>
                                <h3 className="text-md font-semibold">For Administrators:</h3>
                                <p className={'ml-2'}>
                                    We envision a future where administrators are empowered with comprehensive,
                                    data-driven insights that enable informed decision-making and strategic planning.
                                    Our platform will streamline administrative tasks, enhance communication across all
                                    levels of the educational system, and provide the tools necessary to create an
                                    inclusive, efficient, and forward-thinking learning environment.
                                </p>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-md font-semibold">For Teachers:</h3>
                                <p className={'ml-2'}>
                                    We strive to provide the most useful and intuitive data tools for teachers, offering dynamic, adaptable
                                    resources that support diverse teaching styles and student needs. Our vision is to
                                    simplify lesson and unit planning, foster collaboration among educators, and provide
                                    actionable insights that enhance instructional effectiveness, allowing teachers to
                                    focus on what matters most: inspiring and engaging their students.
                                </p>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-md font-semibold">For Students:</h3>
                                <p className={'ml-2'}>
                                    Our vision is to create a world where every student has access to personalized,
                                    responsive learning experiences that cater to their unique abilities and interests.
                                    We are committed to breaking down barriers to education, fostering a love for
                                    learning, and equipping students with the skills and confidence they need to succeed
                                    in a rapidly changing world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mission;